import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { MoreBar } from '@revolut/ui-kit'

import { selectorKeys } from '@src/constants/api'
import { TableNames } from '@src/constants/table'
import { CommonGenericEditableTableRowOptions } from '@src/features/GenericEditableTable/GenericEditableTable'
import { RowInterface } from '@src/interfaces/data'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { ImportEmployeeDataInterface } from '@src/interfaces/importEmployees'
import {
  importEmployeesV2AccessGroupColumn,
  importEmployeesV2EmailColumn,
  importEmployeesV2FirstNameColumn,
  importEmployeesV2LastNameColumn,
  importEmployeesV2LineManagerColumn,
  importEmployeesV2LocationColumn,
  importEmployeesV2NameColumn,
  importEmployeesV2RoleColumn,
  importEmployeesV2SeniorityColumn,
  importEmployeesV2TeamColumn,
} from '@src/constants/columns/importEmployeesV2'
import {
  BulkDeleteButton,
  BulkEditAction,
} from '@src/features/GenericEditableTable/components'
import { CreateRolePopup } from '@src/features/EditableEmployeesTable'
import { IdAndName } from '@src/interfaces'
import { CreateTeamPopup } from './components'
import { BulkDataUploadV2 } from '@src/features/BulkDataUpload'

type CreateCallback = (
  type: 'team' | 'role',
  employeeId: number,
  onChangeAction: (entity: IdAndName) => void,
) => void

const row =
  (onCreate: CreateCallback, isDetailed?: boolean) =>
  (
    options: CommonGenericEditableTableRowOptions,
  ): RowInterface<ImportInterface<ImportEmployeeDataInterface>> => ({
    cells: [
      ...(isDetailed
        ? [{ ...importEmployeesV2NameColumn(options.onChange), width: 250 }]
        : [
            {
              ...importEmployeesV2EmailColumn(options.onChange),
              width: 200,
            },
            {
              ...importEmployeesV2FirstNameColumn(options.onChange),
              width: 150,
            },
            {
              ...importEmployeesV2LastNameColumn(options.onChange),
              width: 150,
            },
          ]),
      {
        ...importEmployeesV2TeamColumn(options.onChange, (id, onChangeAction) =>
          onCreate('team', id, onChangeAction),
        ),
        width: 150,
      },
      {
        ...importEmployeesV2RoleColumn(options.onChange, (id, onChangeAction) =>
          onCreate('role', id, onChangeAction),
        ),
        width: 150,
      },
      {
        ...importEmployeesV2SeniorityColumn(options.onChange),
        width: 150,
      },
      ...(isDetailed
        ? [
            {
              ...importEmployeesV2LineManagerColumn(options.onChange),
              width: 200,
            },
            {
              ...importEmployeesV2LocationColumn(options.onChange),
              width: 200,
            },
          ]
        : [
            {
              ...importEmployeesV2AccessGroupColumn(options.onChange),
              width: 200,
            },
          ]),
    ],
  })

const getEnabledButtons = (
  type?: string,
): {
  team?: boolean
  specialisation?: boolean
  seniority?: boolean
  access_level?: boolean
  line_manager?: boolean
  location?: boolean
} => {
  switch (type) {
    case 'team':
      return {
        team: true,
      }
    case 'specialisation':
    case 'seniority':
      return {
        specialisation: true,
        seniority: true,
      }
    case 'access_level':
      return {
        access_level: true,
      }
    case 'line_manager':
      return {
        line_manager: true,
      }
    case 'location':
      return {
        location: true,
      }
    default:
      return {
        team: true,
        specialisation: true,
        seniority: true,
        access_level: true,
      }
  }
}

const getHiddenColumns = (type?: string): Record<string, boolean> => {
  switch (type) {
    case 'team':
    case 'line_manager':
    case 'location':
      return {
        specialisation: true,
        seniority: true,
        access_level: true,
      }
    case 'specialisation':
    case 'seniority':
      return {
        team: true,
        access_level: true,
      }
    case 'access_level':
      return {
        team: true,
        specialisation: true,
        seniority: true,
      }
    default:
      return {}
  }
}

const getHeaderTitle = (type?: string) => {
  switch (type) {
    case 'team':
      return 'Review bulk assigned team'
    case 'specialisation':
      return 'Review bulk assigned role'
    case 'seniority':
      return 'Review bulk assigned seniority'
    case 'access_level':
      return 'Review bulk assigned access group'
    default:
      return 'Import employees'
  }
}

type Props = {
  getHeader: (title: string) => React.ReactNode
  importRoute: string
  sessionRoute: string
  anyRoute: string
  apiEndpoint: string
  isDetailed?: boolean
  canConfigureBulkUploadFields?: boolean
}
export const BulkEmployeeUploadFlow = ({
  getHeader,
  importRoute,
  sessionRoute,
  anyRoute,
  apiEndpoint,
  isDetailed,
  canConfigureBulkUploadFields,
}: Props) => {
  const [createNewTypeState, setCreateNewTypeState] = useState<{
    type: 'team' | 'role'
    employeeId: number
    onChangeAction: (entity: IdAndName) => void
  }>()

  const onCreateNew = (
    type: 'team' | 'role',
    employeeId: number,
    onChangeAction: (entity: IdAndName) => void,
  ) => {
    setCreateNewTypeState({ type, employeeId, onChangeAction })
  }

  const params = useParams<{ type?: string }>()

  const { title, hiddenColumns } = useMemo(
    () => ({
      title: getHeaderTitle(params?.type),
      hiddenColumns: getHiddenColumns(params?.type),
    }),
    [params?.type],
  )

  const enabledButtons = getEnabledButtons(params.type)

  return (
    <>
      <BulkDataUploadV2
        importRoute={importRoute}
        sessionRoute={sessionRoute}
        anyRoute={anyRoute}
        apiEndpoint={apiEndpoint}
        category="employee_upload"
        name="employee"
        tableName={TableNames.ImportEmployeesV2}
        row={row(onCreateNew, isDetailed)}
        entity="employee"
        templateParams={{ version: '2', extra_columns: 'access_level' }}
        header={getHeader(title)}
        hiddenColumns={hiddenColumns}
        canConfigureBulkUploadFields={canConfigureBulkUploadFields}
        tableActions={props => (
          <MoreBar>
            {enabledButtons.team && (
              <BulkEditAction
                buttonIcon="Suitcase"
                field="team"
                selector={selectorKeys.team}
                {...props}
              />
            )}
            {enabledButtons.specialisation && (
              <BulkEditAction
                buttonIcon="RepairTool"
                field="specialisation"
                selector={selectorKeys.approved_specialisations}
                {...props}
                label="role"
              />
            )}
            {enabledButtons.seniority && (
              <BulkEditAction
                buttonIcon="ArrowRightLeft"
                field="seniority"
                selector={selectorKeys.seniority}
                {...props}
              />
            )}
            {isDetailed && enabledButtons.line_manager && (
              <BulkEditAction
                buttonIcon="Profile"
                field="line_manager"
                label="manager"
                selector={selectorKeys.employee}
                {...props}
              />
            )}
            {isDetailed && enabledButtons.location && (
              <BulkEditAction
                buttonIcon="LocationPin"
                field="location"
                label="location"
                selector={selectorKeys.location}
                {...props}
              />
            )}
            {!isDetailed && enabledButtons.access_level && (
              <BulkEditAction
                buttonIcon="Switches"
                field="access_level"
                label="access group"
                selector={selectorKeys.groups}
                {...props}
              />
            )}
            <BulkDeleteButton {...props} />
          </MoreBar>
        )}
      />

      <CreateTeamPopup
        open={createNewTypeState?.type === 'team'}
        onSuccess={team => {
          createNewTypeState?.onChangeAction(team)
          setCreateNewTypeState(undefined)
        }}
        onClose={() => setCreateNewTypeState(undefined)}
      />

      <CreateRolePopup
        open={createNewTypeState?.type === 'role'}
        onSuccess={role => {
          createNewTypeState?.onChangeAction(role)
          setCreateNewTypeState(undefined)
        }}
        onClose={() => setCreateNewTypeState(undefined)}
        isNewSpecialisationOnly={false}
      />
    </>
  )
}
