import React from 'react'
import { Text } from '@revolut/ui-kit'

import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { ImportEmployeeDataInterface } from '@src/interfaces/importEmployees'
import { selectorKeys } from '../api'
import {
  GenericEditableTableColumn,
  GenericEditableTableOnChange,
  RadioSelectInputCell,
  TextCell,
} from '@src/features/GenericEditableTable/components'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { IdAndName } from '@src/interfaces'

type ImportEmployeeColumn = GenericEditableTableColumn<ImportEmployeeDataInterface>

type ImportEmployeeWithCreateNewColumn = (
  onChange: GenericEditableTableOnChange,
  onCreate: (id: number, onChangeAction: (newEntity: IdAndName) => void) => void,
  isPreview?: boolean,
) => ColumnInterface<ImportInterface<ImportEmployeeDataInterface>>

export const importEmployeesV2FirstNameColumn: ImportEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'first_name',
  dataPoint: 'first_name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'First name',
  insert: ({ data }) => <TextCell data={data} onChange={onChange} field="first_name" />,
})

export const importEmployeesV2LastNameColumn: ImportEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'last_name',
  dataPoint: 'last_name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Last name',
  insert: ({ data }) => <TextCell data={data} onChange={onChange} field="last_name" />,
})

export const importEmployeesV2NameColumn: ImportEmployeeColumn = () => ({
  type: CellTypes.insert,
  idPoint: 'last_name',
  dataPoint: 'last_name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Name',
  insert: ({ data }) => (
    <Text>
      {data.data.first_name} {data.data.last_name}
    </Text>
  ),
})

export const importEmployeesV2EmailColumn: ImportEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'email',
  dataPoint: 'email',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Work email',
  insert: ({ data }) => <TextCell data={data} onChange={onChange} field="email" />,
})

export const importEmployeesV2TeamColumn: ImportEmployeeWithCreateNewColumn = (
  onChange,
  onCreate,
) => ({
  type: CellTypes.insert,
  idPoint: 'team',
  dataPoint: 'team',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Team',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="team"
      selector={selectorKeys.team}
      useNameField
      onCreateNewClick={onCreate}
    />
  ),
})

export const importEmployeesV2RoleColumn: ImportEmployeeWithCreateNewColumn = (
  onChange,
  onCreate,
) => ({
  type: CellTypes.insert,
  idPoint: 'specialisation',
  dataPoint: 'specialisation',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Role',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="specialisation"
      selector={selectorKeys.approved_specialisations}
      useNameField
      fieldName="role"
      onCreateNewClick={onCreate}
    />
  ),
})

export const importEmployeesV2SeniorityColumn: ImportEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'seniority',
  dataPoint: 'seniority',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Seniority',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="seniority"
      selector={selectorKeys.seniority}
      useNameField
    />
  ),
})

export const importEmployeesV2LineManagerColumn: ImportEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'line_manager.id',
  dataPoint: 'line_manager.full_name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Line manager',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="line_manager"
      selector={selectorKeys.employee}
      useNameField
    />
  ),
})

export const importEmployeesV2LocationColumn: ImportEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'location.id',
  dataPoint: 'location.name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Seniority',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="location"
      selector={selectorKeys.location}
      useNameField
    />
  ),
})

export const importEmployeesV2AccessGroupColumn: ImportEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'access_level',
  dataPoint: 'access_level',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Access group',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="access_level"
      fieldName="access group"
      selector={selectorKeys.groups}
      useNameField
    />
  ),
})
